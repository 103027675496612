import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSwitch.figmaUrl.ios} codeUrl={checklists.componentSwitch.codeUrl.ios} checklists={checklists.componentSwitch.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Switch are used for activating one of two predefined options`}</p>
    <p>{`Use Legion switch to modify the responsive toggle buttons that supports default and small sizes, and an optional text label on its right side etc.`}</p>
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`Switch(initialValue: true)
`}</code></pre>
    <h3>{`Usage Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSwitch(initialValue: true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switchios.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Legion Have 2 Variant of Switch :`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand ON`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/on.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`Switch(initialValue: true, onToggleChange: {value in print(value)} ) `}</code>{`
    `}</pre>{`
    Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNSwitch(initialValue: true, onToggleChange: {value in print(value)} ) `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand OFF`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/off.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`Switch(initialValue: false, onToggleChange: {value in print(value)} ) `}</code>{`
    `}</pre>{`
    Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNSwitch(initialValue: false, onToggleChange: {value in print(value)} ) `}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 3 Attributes for Customize Switch :`}</p>
    <h3><strong parentName="h3">{` Size `}</strong></h3>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Small`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/small.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        Switch(initialValue: true, sizeType: LGNSwitch.SizeType.small, onToggleChange: {newValue in print(value)} ){" "}
      `}</code>{`
    `}</pre>{`
    Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNSwitch(initialValue: true, sizeType: LGNSwitch.SizeType.small, onToggleChange: {newValue in print(value)}{" "}
        ){" "}
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Default`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/default.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        Switch(initialValue: true, sizeType: LGNSwitch.SizeType.default, onToggleChange: {newValue in print(value)}{" "}
        ){" "}
      `}</code>{`
    `}</pre>{`
    Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNSwitch(initialValue: true, sizeType: LGNSwitch.SizeType.default, onToggleChange: {newValue in print(value)}{" "}
        ){" "}
      `}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h3><strong parentName="h3">{` Disable `}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/disable.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`This code sample demonstrates how to modify disable of the switch :`}</p>
    <p>{`Legion UI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   Switch(
        initialValue: true,
        isDisabled: .constant(true)
    )
`}</code></pre>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   LGNSwitch(
        initialValue: true,
        isDisabled: .constant(true)
    )
`}</code></pre>
    <div className="divi" />
    <h3><strong parentName="h3">{` Label `}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/label.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`This code sample demonstrates how to modify label of the button :`}</p>
    <p>{`Legion UI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   LGNSwitch("Label", initialValue: true, isDisabled: .constant(true))
`}</code></pre>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   LGNSwitch(
        "Label",
        initialValue: true,
        isDisabled: .constant(true)
    )
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text that should appear on the right side of the swift`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`initialValue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The initial state of the Switch. Whether it is turned on (`}<inlineCode parentName="td">{`true`}</inlineCode>{`) or off (`}<inlineCode parentName="td">{`false`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sizeType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the toggle Either `}<inlineCode parentName="td">{`.default`}</inlineCode>{` or `}<inlineCode parentName="td">{`.small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.default`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string of font family name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Nurito-Sans`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textForegroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color to be used for the text label on the right, when the view is not disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary800`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textDisabledColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color to be used for the text label on the right, when the view is disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary50`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isDisabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A binding boolean to enable disable the component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.constant(false)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onToggleChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when user toggles the switch.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Function Name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`setTintColor`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The LGNSwitch can change the tint color of the switch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`animateBeamEffectWhenToggleOn()`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The LGNSwitch can be animated like beam effect when the toggle change from off to on`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`animateBounceWhenToggleOn()`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The LGNSwitch can be animated like bouncing when the toggle change from off to on`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <p>{`LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   Switch(
  "Label",
  initialValue: true,
  sizeType: .default,
  fontFamily: "NunitoSans-Bold",
  textForegroundColor: Color.LGNTheme.tertiary800,
  textDisabledColor: Color.LGNTheme.tertiary500,
  isDisabled: .constant(false),
  onToggleChange: { value in
    print(value)
  }
)
.animateBounceWhenToggleOn(isActive: .constant(true), repeatCount: 1) // or
.animateBeamEffectWhenToggleOn(isActive: .constant(true), repeatCount: 1, isGoingOutside: true, color: nil)
`}</code></pre>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   Switch(
  "Label",
  initialValue: true,
  sizeType: .default,
  fontFamily: "NunitoSans-Bold",
  textForegroundColor: Color.LGNTheme.tertiary800,
  textDisabledColor: Color.LGNTheme.tertiary500,
  isDisabled: .constant(false),
  onToggleChange: { value in
    print(value)
  }
)
.animateBounceWhenToggleOn(isActive: .constant(true), repeatCount: 1) // or
.animateBeamEffectWhenToggleOn(isActive: .constant(true), repeatCount: 1, isGoingOutside: true, color: nil)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      